import React from 'react';
import './App.css';
import MainPage from './containers/MainPage';

function App() {
  return (
    <div className="App">
      <div class="area" >
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >
      <main>
        <MainPage />
      </main>
    </div>
  );
}

export default App;
