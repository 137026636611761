import React from 'react';
import "./css/MainPage.css";
import ChainFiLogo from "../img/chain-fi-logo.png";
import SmarteamingLogo from "../img/smarteaming-logo.png";

const MainPage = () => (
  <div className="main-page">
    <header className="header">
      <h1>Mathias Pellegrin</h1>
      <h2>FullStack Web-Developer</h2>
    </header>
    <main className="content">
    <div className="card">
  <div className="header">
    <div className="top">
      <div className="thecircle">
        <span className="red thecircle2"></span>
      </div>
      <div className="thecircle">
        <span className="yellow thecircle2"></span>
      </div>
      <div className="thecircle">
        <span className="green thecircle2"></span>
      </div>
      <div className="title">
        <p id="title2">About me</p>
      </div>
    </div>
  </div>
  <div className="code-container">
  <textarea readonly name="code" id="code" className="area">
    Hi! I am the creator of this website, a passionate FullStack Web Developer 
    with experience in building dynamic and responsive web applications. I enjoy 
    solving complex problems and continuously learning new technologies to enhance 
    my skills.
    
    My expertise includes working with modern frameworks and libraries like React, 
    Node.js, and Express. I am dedicated to delivering high-quality code and 
    collaborating with cross-functional teams to create impactful solutions. I've 
    been handling both the FrontEnd and the BackEnd of the projects mentioned below.
  </textarea>
</div>
</div>
      <section className="projects">
        <h2>My Latest Projects</h2>
        <div className='all-projects'>
          <div
            className="project-item"
            onClick={() => window.open("https://smarteaming.com", "_blank")}
          >
            <div className="project-details">
              <img src={SmarteamingLogo} alt="Smarteaming Logo" className="project-logo" />
              <h3>Smarteaming</h3>
            </div>
          </div>
          <div
            className="project-item"
            onClick={() => window.open("https://caalio.xyz", "_blank")}
          >
            <div className="project-details">
              <img src={ChainFiLogo} alt="Chain-fi Logo" className="project-logo" />
              <h3>Chain-fi</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="contact">
        <h2>Contact</h2>
        <p>Email: <a href="mailto:mathias.pellegrin.pro@gmail.com">mathias.pellegrin.pro@gmail.com</a></p>
        <p>Phone: +32 488 55 75 61</p>
        <ul class="example-2">
  <li class="icon-content">
    <a
      href="https://discord.gg/N3HzkCAX7j"
      aria-label="Discord"
      data-social="Discord"
      target='_blank'
      rel='noreferrer'
    >
      <div class="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
  <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
</svg>
    </a>
    <div class="tooltip">Discord</div>
  </li>
  <li class="icon-content">
    <a
      href="https://www.linkedin.com/in/mathias-pellegrin-793332239/"
      aria-label="Linkedin"
      data-social="Linkedin"
      target='_blank'
      rel='noreferrer'
    >
      <div class="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
</svg>
    </a>
    <div class="tooltip">Linkedin</div>
  </li>
  <li class="icon-content">
    <a
      href="https://www.youtube.com/@CLETUSS_"
      aria-label="Youtube"
      data-social="Youtube"
      target='_blank'
      rel='noreferrer'
    >
      <div class="filled"></div>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg>
    </a>
    <div class="tooltip">Youtube</div>
  </li>
  <li class="icon-content">
    <a
      href="https://t.me/CLETUSSYT"
      aria-label="Telegram"
      data-social="telegram"
      target='_blank'
      rel='noreferrer'
    >
      <div class="filled"></div>
      <svg version="1.1" viewBox="0 0 100 100">
        <path
          d="M95,9.9c-1.3-1.1-3.4-1.2-7-0.1c0,0,0,0,0,0c-2.5,0.8-24.7,9.2-44.3,17.3c-17.6,7.3-31.9,13.7-33.6,14.5  c-1.9,0.6-6,2.4-6.2,5.2c-0.1,1.8,1.4,3.4,4.3,4.7c3.1,1.6,16.8,6.2,19.7,7.1c1,3.4,6.9,23.3,7.2,24.5c0.4,1.8,1.6,2.8,2.2,3.2  c0.1,0.1,0.3,0.3,0.5,0.4c0.3,0.2,0.7,0.3,1.2,0.3c0.7,0,1.5-0.3,2.2-0.8c3.7-3,10.1-9.7,11.9-11.6c7.9,6.2,16.5,13.1,17.3,13.9  c0,0,0.1,0.1,0.1,0.1c1.9,1.6,3.9,2.5,5.7,2.5c0.6,0,1.2-0.1,1.8-0.3c2.1-0.7,3.6-2.7,4.1-5.4c0-0.1,0.1-0.5,0.3-1.2  c3.4-14.8,6.1-27.8,8.3-38.7c2.1-10.7,3.8-21.2,4.8-26.8c0.2-1.4,0.4-2.5,0.5-3.2C96.3,13.5,96.5,11.2,95,9.9z M30,58.3l47.7-31.6  c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1c-0.1,0.1-0.2,0.4-0.4,0.6L66,38.1  c-8.4,7.7-19.4,17.8-26.7,24.4c0,0,0,0,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1  c0,0,0,0,0,0.1c-0.5,5.6-1.4,15.2-1.8,19.5c0,0,0,0,0-0.1C36.8,81.4,31.2,62.3,30,58.3z"
          fill="currentColor"
        ></path>
      </svg>
    </a>
    <div class="tooltip">Telegram</div>
  </li>
</ul>

      </section>
    </main>
  </div>
);

export default MainPage;
